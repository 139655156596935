<template>
  <div class="invite-user">
    <div class="invite-funnel">
      <div class="invite-funnel__main-block">
        <div class="invite-funnel__main-block__inner">
          <div class="invite-funnel__main-block__heading">
            <a href="https://www.letsconnect.at/" class="invite-funnel__main-block__heading__logo-wrapper">
              <img src="@/assets/images/register-funnel/vega_logo.svg" alt="Logo" class="invite-funnel__main-block__heading__logo-img" />
            </a>
          </div>

          <div v-if="!hasActivateCodeExpired" class="invite-funnel__main-block__body">
            <div class="invite-funnel__main-block__flow">
              <div class="invite-funnel__main-block__flow__heading invite-funnel--code-expired">
                <div class="invite-funnel__main-block__flow__heading__title">{{ $t('invite-user.welcome-heading') }}</div>
                <div class="invite-funnel__main-block__flow__heading__description">{{ $t('invite-user.welcome-message') }}</div>
                <vs-progress v-if="hasUserInvitationProcessStarted" indeterminate color="primary">primary</vs-progress>

                <div v-if="!hasUserInvitationProcessStarted" class="invite-funnel__main-block__flow__content__next-button-wrapper">
                  <div class="invite-funnel__main-block__flow__content__next-button" @click="onStartUserInvitationSetupProcess">
                    <template>{{ $t('vue.continue') }}</template>

                    <img
                      src="@/assets/images/register-funnel/forward-arrow.png"
                      alt="continue"
                      class="invite-funnel__main-block__flow__content__next-button__icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else class="invite-funnel__main-block__body">
            <div class="invite-funnel__main-block__flow">
              <div class="invite-funnel__main-block__flow__heading invite-funnel--code-expired">
                <div class="invite-funnel__main-block__flow__heading__title">{{ $t('vue.invitationLinkExpired') }}</div>
                <div class="invite-funnel__main-block__flow__heading__description">{{ $t('vue.invitationLinkExpiredMessage') }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="invite-funnel__info-block">
        <div class="invite-funnel__info-block__inner">
          <TeamIcon />
          <div class="invite-funnel__info-block__title">{{ $t('invite-user.welcome-heading') }}</div>
          <div class="invite-funnel__info-block__description">{{ $t('invite-user.welcome-message') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeamIcon from './TeamIcon.vue'
export default {
  name: 'User-Onboarding',
  components: {
    TeamIcon
  },
  data() {
    return {
      hasActivateCodeExpired: false,
      isUserInvitationSetup: false,
      hasUserInvitationProcessStarted: false
    }
  },
  async mounted() {
    const firebaseCurrentUser = this.$firebase.auth().currentUser

    if (firebaseCurrentUser) {
      await this.$firebase.auth().signOut()
    }
    this.checkEmailLogin()
  },
  methods: {
    async checkEmailLogin() {
      if (this.$route && this.$route.query && this.$route.query.apiKey) {
        this.isUserInvitationSetup = true
      } else {
        this.hasActivateCodeExpired = true
      }
    },
    async onStartUserInvitationSetupProcess() {
      const vm = this
      vm.hasUserInvitationProcessStarted = true
      if (vm.$firebase.auth().isSignInWithEmailLink(window.location.href)) {
        let email = window.localStorage.getItem('emailForSignIn') || vm.$route.query.email
        if (!email) {
          email = window.prompt('Please provide your email for confirmation')
        }
        localStorage.removeItem('emailForSignIn')
        localStorage.clear()
        // The client SDK will parse the code from the link for you.
        vm.$firebase
          .auth()
          .signInWithEmailLink(email, window.location.href)
          .then(async (result) => {
            if (vm.$route.query.userId) {
              const setUserClaim = vm.$functions.httpsCallable('setUserClaim')
              await setUserClaim({
                userId: vm.$route.query.userId
              })
            }
            await result.user.getIdToken(true)
            vm.$router.push({ name: 'page-set-password' })
          })
          .catch((error) => {
            /* eslint-disable no-console */
            this.isLoading = false

            if (error.code === 'auth/invalid-action-code') {
              this.hasActivateCodeExpired = true
            }
            if (window.location.search) {
              window.history.pushState({}, '', window.location.pathname)
            }
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './invite-funnel.scss';
</style>
