var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "invite-user" }, [
    _c("div", { staticClass: "invite-funnel" }, [
      _c("div", { staticClass: "invite-funnel__main-block" }, [
        _c("div", { staticClass: "invite-funnel__main-block__inner" }, [
          _vm._m(0),
          !_vm.hasActivateCodeExpired
            ? _c("div", { staticClass: "invite-funnel__main-block__body" }, [
                _c("div", { staticClass: "invite-funnel__main-block__flow" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "invite-funnel__main-block__flow__heading invite-funnel--code-expired",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "invite-funnel__main-block__flow__heading__title",
                        },
                        [_vm._v(_vm._s(_vm.$t("invite-user.welcome-heading")))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "invite-funnel__main-block__flow__heading__description",
                        },
                        [_vm._v(_vm._s(_vm.$t("invite-user.welcome-message")))]
                      ),
                      _vm.hasUserInvitationProcessStarted
                        ? _c(
                            "vs-progress",
                            { attrs: { indeterminate: "", color: "primary" } },
                            [_vm._v("primary")]
                          )
                        : _vm._e(),
                      !_vm.hasUserInvitationProcessStarted
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "invite-funnel__main-block__flow__content__next-button-wrapper",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "invite-funnel__main-block__flow__content__next-button",
                                  on: {
                                    click:
                                      _vm.onStartUserInvitationSetupProcess,
                                  },
                                },
                                [
                                  [_vm._v(_vm._s(_vm.$t("vue.continue")))],
                                  _c("img", {
                                    staticClass:
                                      "invite-funnel__main-block__flow__content__next-button__icon",
                                    attrs: {
                                      src: require("@/assets/images/register-funnel/forward-arrow.png"),
                                      alt: "continue",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ])
            : _c("div", { staticClass: "invite-funnel__main-block__body" }, [
                _c("div", { staticClass: "invite-funnel__main-block__flow" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "invite-funnel__main-block__flow__heading invite-funnel--code-expired",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "invite-funnel__main-block__flow__heading__title",
                        },
                        [_vm._v(_vm._s(_vm.$t("vue.invitationLinkExpired")))]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "invite-funnel__main-block__flow__heading__description",
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("vue.invitationLinkExpiredMessage"))
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
        ]),
      ]),
      _c("div", { staticClass: "invite-funnel__info-block" }, [
        _c(
          "div",
          { staticClass: "invite-funnel__info-block__inner" },
          [
            _c("TeamIcon"),
            _c("div", { staticClass: "invite-funnel__info-block__title" }, [
              _vm._v(_vm._s(_vm.$t("invite-user.welcome-heading"))),
            ]),
            _c(
              "div",
              { staticClass: "invite-funnel__info-block__description" },
              [_vm._v(_vm._s(_vm.$t("invite-user.welcome-message")))]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "invite-funnel__main-block__heading" }, [
      _c(
        "a",
        {
          staticClass: "invite-funnel__main-block__heading__logo-wrapper",
          attrs: { href: "https://www.letsconnect.at/" },
        },
        [
          _c("img", {
            staticClass: "invite-funnel__main-block__heading__logo-img",
            attrs: {
              src: require("@/assets/images/register-funnel/vega_logo.svg"),
              alt: "Logo",
            },
          }),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }